import { createRoot, hydrateRoot } from 'react-dom/client';
import { initWeglot, isOnTranslatedPage } from './weglot-browser';

export const replaceHydrateFunction = () => {
  return (element, container) => {
    if(window.__neonodeIsDev) {
      console.log('** gatsby-browser.js: Dev mode - render root.');

      const root = createRoot(container);
      
      root.render(element);

      return;
    }

    if(isOnTranslatedPage()) {
      console.log('** gatsby-browser.js: Weglot-instance found, English not selected - render root.');

      const root = createRoot(container);
      
      root.render(element);
    } else {
      console.log('** gatsby-browser.js: Hydrate root.');

      hydrateRoot(container, element);
    }
  }
}

export const onClientEntry = () => {
  initWeglot();
}

export default {};