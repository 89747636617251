exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-articles-paginate-index-tsx": () => import("./../../../src/templates/ArticlesPaginate/index.tsx" /* webpackChunkName: "component---src-templates-articles-paginate-index-tsx" */),
  "component---src-templates-event-index-tsx": () => import("./../../../src/templates/Event/index.tsx" /* webpackChunkName: "component---src-templates-event-index-tsx" */),
  "component---src-templates-events-paginate-index-tsx": () => import("./../../../src/templates/EventsPaginate/index.tsx" /* webpackChunkName: "component---src-templates-events-paginate-index-tsx" */),
  "component---src-templates-page-index-tsx": () => import("./../../../src/templates/Page/index.tsx" /* webpackChunkName: "component---src-templates-page-index-tsx" */),
  "component---src-templates-press-release-index-tsx": () => import("./../../../src/templates/PressRelease/index.tsx" /* webpackChunkName: "component---src-templates-press-release-index-tsx" */),
  "component---src-templates-press-releases-paginate-index-tsx": () => import("./../../../src/templates/PressReleasesPaginate/index.tsx" /* webpackChunkName: "component---src-templates-press-releases-paginate-index-tsx" */),
  "component---src-templates-resources-paginate-index-tsx": () => import("./../../../src/templates/ResourcesPaginate/index.tsx" /* webpackChunkName: "component---src-templates-resources-paginate-index-tsx" */),
  "component---src-templates-sub-page-index-tsx": () => import("./../../../src/templates/SubPage/index.tsx" /* webpackChunkName: "component---src-templates-sub-page-index-tsx" */),
  "component---src-templates-success-stories-paginate-index-tsx": () => import("./../../../src/templates/SuccessStoriesPaginate/index.tsx" /* webpackChunkName: "component---src-templates-success-stories-paginate-index-tsx" */),
  "component---src-templates-success-story-index-tsx": () => import("./../../../src/templates/SuccessStory/index.tsx" /* webpackChunkName: "component---src-templates-success-story-index-tsx" */),
  "component---src-templates-white-paper-index-tsx": () => import("./../../../src/templates/WhitePaper/index.tsx" /* webpackChunkName: "component---src-templates-white-paper-index-tsx" */),
  "component---src-templates-white-papers-paginate-index-tsx": () => import("./../../../src/templates/WhitePapersPaginate/index.tsx" /* webpackChunkName: "component---src-templates-white-papers-paginate-index-tsx" */)
}

