const DEFAULT_LANG = 'en';

/**
 * Is on translated page?
 */
export function isOnTranslatedPage() {
  return window.Weglot && window.Weglot.getCurrentLang() !== DEFAULT_LANG;
}

/**
 * Has Weglot script?
 */
export function hasWeglotScript() {
  return window.__hasWeglotScript || false;
}

/**
 * Init Weglot
 * 
 * Should be executed in client
 */
export function initWeglot() {
  if(window.Weglot) {
    window.Weglot.on('switchersReady', () => {
      const event = new CustomEvent('WeglotReady');

      window.dispatchEvent(event); 
    });
  } else {
    console.warn('** weglot.jsx: Could not initialize Weglot: window.Weglot not found');
  }
}
